import React, { useEffect } from "react"
import { connect } from "react-redux"
import Parser from "html-react-parser"
import moment from "moment"
import SEO from "./seo"
import Slider2 from "./slider2"

import { getPostDetails } from "../store/actions/postActions"
import { BASE_URL } from "../utils/urls"
import Layout from "./layout"

const PostsDetail = ({ postId, isLoading, post, getPostDetails }) => {
  useEffect(() => {
    getPostDetails(postId)
  }, [])
  const pageName = "ARTICLE DU BLOG"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-col justify-center py-6">
        {isLoading ? (
          <div className="flex container mx-auto py-6">
            <div
              className="w-full mx-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-center"
              role="alert"
            >
              <span className="block sm:inline">Chargement en cours ...</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap container mx-auto py-6 px-4">
            <div className="w-1/4"></div>
            <div className="w-2/4">
              <div className="text-2xl font-bold text-blue-600 text-center pb-4">
                {post.title}
              </div>
              <img
                src={BASE_URL + "/storage/" + post.cover}
                className="w-full"
              />
            </div>
            <div className="w-1/4"></div>
            <div className="w-full">
              <div className="pt-0 pb-8 border-b text-sm text-center italic">
                {"Posté le : " +
                  moment(post.created_at).format("DD/MM/YYYY à HH:mm")}
              </div>
            </div>
            <div className="text-sm py-3">
              {post.content ? Parser(post.content) : ""}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

const mapStateToProps = state => ({
  isLoading: state.post.isLoading,
  post: state.post.post,
})

const mapDispatchToProps = {
  getPostDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsDetail)
