import React, { useEffect } from "react"
import { Link } from "gatsby"

import { getPosts } from "../store/actions/postActions"
import { connect } from "react-redux"
import moment from "moment"
import SEO from "./seo"
import Slider2 from "./slider2"
import { BASE_URL } from "../utils/urls"
import Layout from "./layout"

const PostsList = ({ getPosts, posts, isPostLoading }) => {
  useEffect(() => {
    getPosts()
  }, [])
  const pageName = "BLOG"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-col justify-center py-6">
        <div className="container mx-auto">
          <div className="flex flex-col">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Blog
            </span>
            <h1 className="text-center text-2xl">
              Des conseils pour garder vos vêtements neufs
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              “Il n'y a plus de mode, rien que des vêtements.“
              <span className="font-bold">- Karl Lagerfeld (1933 - 2019)</span>
              <br />
              Mon E-Pressing vous offre gratuitement des pratiques, <br />
              astuces et infos vestimentaires. 😁
            </span>
          </div>
          <div className="flex flex-wrap pb-4">
            {isPostLoading && (
              <div className="w-full mx-5">
                <div
                  className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <div>Chargement en cours</div>
                </div>
              </div>
            )}
            {posts &&
              posts.map((post, key) => (
                <div className="w-full sm:w-1/2 md:w-1/3 px-2 py-3" key={key}>
                  <Link
                    to={"/posts/detail/" + post.id}
                    className="max-w-sm rounded overflow-hidden shadow-lg bg-white"
                  >
                    <img
                      className="w-full h-48 rounded"
                      src={BASE_URL + "/storage/" + post.cover}
                      alt=""
                    />
                    <div className="pt-0 pb-2 mx-4 text-center">
                      <div className="font-bold text-base mb-2">
                        {post.title}
                      </div>
                      <p className="text-gray-600 italic text-xs">
                        Publié le&nbsp;
                        {moment(post.created_at).format("DD/MM/YYYY à HH:mm")}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isPostLoading: state.post.isLoading,
    posts: state.post.posts,
  }
}

const mapDispatchToProps = {
  getPosts,
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsList)
