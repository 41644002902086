import React from "react"
import { Router } from "@reach/router"

import PostsList from "../components/postsList"
import PostsDetail from "../components/postsDetail"

const Posts = () => {
  return (
    <Router basepath="/posts">
      <PostsList path="/list" />
      <PostsDetail path="/detail/:postId" />
    </Router>
  )
}

export default Posts
